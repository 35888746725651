<template>
  <div class="invited">

    <div class="info-box">
        <div class="">
            <van-cell-group inset>
                <van-field v-model="value" placeholder="请输入邮箱" clearable/>
            </van-cell-group>
        </div>
    </div>

    <div class="btn-box">
        <div class="btn-inner">
            <div class="btn-item" @click="setSignInStatus(1)">保 存</div>
        </div>
    </div>
  </div>
</template>

<script>
import { button, Toast, NavBar, Cell, CellGroup, List ,Tabbar, Field} from "vant";
import Cookies from "js-cookie";
import config from "@/config";
import TabberItem from "@/components/TabberItem";
export default {
  name: "index",
  data() {
    return {
      value: '',
    };
  },
  methods: {
    // 根据code获取openId
    async getWeChatOpenId() {
      let params = {
        code: this.code,
      };
      await this.$post(`/h5/weChat/getWeChatOpenId`, params).then((res) => {
        if (res.code == "200") {
          let seconds = 60;//设置过期时间（秒）
          let expires = new Date(new Date() * 1 + seconds * 1000);

          Cookies.set("openid", res.result.openid, { expires: 7 });
          // console.info('获取了openId:'+res.result.openid)
          this.queryConferenceUserIsBind();
        }
      });
    },
    linkUrl(url){
        this.$router.push({path:url})
    }
  },
  created() {
    // this.initFn();
  },
  mounted() {},
  components: {
    [Toast.name]: Toast,
    [button.name]: button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [List.name]: List,
    [NavBar.name]: NavBar,
    [Tabbar.name]: Tabbar,
    [Field.name]: Field,
    TabberItem
  },
};
</script>

<style lang="scss" scoped>

.invited {
  background: #f4f4f4;
  min-height: 100vh;
  overflow: hidden;
}
.info-box{
    margin: 15px 0;
    .van-cell{
        padding: 15px;
        font-size: 16px;
    }
}
.c-4D4D4D{
    color: #4D4D4D;
}
.w40{
    flex: 0 0 40%;
}
.w55{
    flex: 0 0 55%;
}

</style>